<template>
  <div class="transdata_wrap radius-8">
    <div class="head_wrap p-t-b-15 p-l-r-30 flex justify-between">
      <div class="left">交易数据</div>
      <div class="right">
        <el-switch
          @change="changeDate"
          v-model="dataType"
          active-text="月"
          inactive-text="日"
        >
        </el-switch>
      </div>
    </div>
    <div class="con_wrap">
      <div :id="idName" :style="{ height: height, width: width }" />
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
import api from "@/api";
export default {
  props: {
    idName: {
      type: String,
      default: "transactionLine",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "400px",
    },
    pieData: { Object, default: Array },
  },
  data() {
    return {
      chart: null,
      dataType: false,
      chartData: [],
    };
  },
  watch: {
    chartData: {
      handler(val) {
        if (!this.$utils.isEmpty(val)) {
          this.initChart();
        }
      },
      deep: true,
    },
  },
  created() {
    this.getTransactionStatisticsDataDay();
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    // 初始化图表
    initChart() {
      var chartDom = document.getElementById(this.idName);
      this.chart = echarts.init(chartDom, "macarons");
      this.setOptions(this.chartData);
    },
    // 配置数据
    setOptions() {
      let option = {
        tooltip: {
          trigger: "axis",
          formatter: "{b0} </br> {a0}: {c0} </br>  {a1} : {c1}",
        },

        legend: {
          show: true,
          top: "10",
          left: "center",
          textStyle: {
            color: "#666",
            fontSize: 12,
          },
          itemGap: 20,

          data: [
            {
              name: "交易金额",
            },
            {
              name: "交易笔数",
            },
          ],
          inactiveColor: "#ccc",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.chartData.map((x) => {
              return x.xAxisData;
            }),
            axisPointer: {
              type: "shadow",
            },

            axisTick: {
              show: true,
              interval: 0,
            },
          },
        ],

        //设置两个y轴，左边显示数量，右边显示概率

        yAxis: [
          {
            type: "value",
            name: "交易金额",
            show: true,
          },
          {
            type: "value",
            name: "交易笔数",
            axisLabel: {
              formatter: "{value} 笔",
            },
          },
        ],

        //每个设备分数量、概率2个指标，只要让他们的name一致，即可通过，legeng进行统一的切换

        series: [
          {
            name: "交易金额",
            type: "bar",
            data: this.chartData.map((x) => {
              return x.lineData;
            }),
            barWidth: "30%",
          },

          {
            name: "交易笔数",
            type: "line",
            yAxisIndex: 1,
            data: this.chartData.map((x) => {
              return x.barData;
            }),
            symbol: "none",
            symbolSize: 0,
            areaStyle: {
              color: "rgb(46,199,201,.3)",
            },
            lineStyle: {
              width: 0,
              color: "rgb(46,199,201,.3)",
            },
            itemStyle: {
              normal: {
                color: "rgb(46,199,201,.3)",
              },
            },
          },
        ],
      };

      this.chart.setOption(option);
    },
    // 日期选择
    changeDate(e) {
      console.log(e);
      if (e) {
        this.getTransactionStatisticsDataMonth();
      } else {
        this.getTransactionStatisticsDataDay();
      }
    },
    // 获取日的数据
    async getTransactionStatisticsDataDay() {
      // transactionStatisticsDataDay
      let result = await api.common.get({
        apiName: "/system/index/transactionStatisticsDataDay",
      });
      let resList = result.data.map((x) => {
        return {
          xAxisData: x.title,
          lineData: x.money,
          barData: x.number,
        };
      });
      this.chartData = resList;
    },
    // 获取日的数据
    async getTransactionStatisticsDataMonth() {
      // transactionStatisticsDataDay
      let result = await api.common.get({
        apiName: "/system/index/transactionStatisticsDataMonth",
      });
      let resList = result.data.map((x) => {
        return {
          xAxisData: x.title,
          lineData: x.money,
          barData: x.number,
        };
      });
      this.chartData = resList;
    },
  },
};
</script>
